<template>
	<div>
		<v-list v-if="!isMobile" nav tile>
			<v-subheader class="mx-2">Menu</v-subheader>

			<v-list-item
				v-for="item in items"
				:key="item.title"
				:to="item.routeName && { name: item.routeName }"
				:href="item.externalLink && 'https://' + item.externalLink"
			>
				<v-tooltip right>
					<template #activator="{ on }">
						<v-list-item-avatar large v-on="on">
							<img :src="item.icon" />
						</v-list-item-avatar>
					</template>
					<span>{{ item.title }}</span>
				</v-tooltip>

				<transition name="fade">
					<v-badge
						:inline="expanded"
						:offset-y="!expanded ? -5 : 0"
						:offset-x="!expanded ? 20 : 0"
						color="#f54e14"
						:content="
							item.title === 'View Interest'
								? notificationCount
								: 0
						"
						:value="
							item.title === 'View Interest'
								? notificationCount
								: 0
						"
					>
						<v-list-item-content v-if="expanded">
							<v-list-item-title
								v-text="item.title"
							></v-list-item-title>
						</v-list-item-content>
					</v-badge>
				</transition>
			</v-list-item>

			<v-list-item>
				<v-icon
					class="ml-4"
					large
					color="grey"
					@click="expanded = !expanded"
					>{{
						!expanded ? "mdi-chevron-right" : "mdi-chevron-left"
					}}</v-icon
				>
			</v-list-item>
		</v-list>

		<v-list v-if="isMobile" nav>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="title">
						Menu
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<v-list-item
				v-for="item in items"
				:key="item.title"
				:to="item.routeName && { name: item.routeName }"
				:href="item.externalLink && 'https://' + item.externalLink"
			>
				<v-list-item-avatar large>
					<img :src="item.icon" />
				</v-list-item-avatar>

				<v-badge
					inline
					color="#f54e14"
					:content="
						item.title === 'View Interest' ? notificationCount : 0
					"
					:value="
						item.title === 'View Interest' ? notificationCount : 0
					"
				>
					<v-list-item-content>
						<v-list-item-title
							v-text="item.title"
						></v-list-item-title>
					</v-list-item-content>
				</v-badge>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "sideNavItems",
	props: ["isMobile", "items"],
	data() {
		return {
			expanded: false
		};
	},
	computed: {
		...mapGetters(["notificationCount"])
	}
};
</script>

<style lang="sass" scoped>
.fade-enter-active, .fade-leave-active 
	transition: opacity .25s


.fade-enter, .fade-leave-to 
	opacity: 0

</style>