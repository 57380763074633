<template>
	<div id="sideNav" :class="isMobile ? 'mobile' : 'desktop'">
		<sideNavItems :items="roleBasedItems" :isMobile="isMobile" />
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import sideNavItems from "./Side Nav Items/sideNavItems.vue";

export default {
	name: "sideNav",
	props: ["isMobile"],
	components: {
		sideNavItems
	},
	computed: {
		...mapGetters(["isLoggedIn", "role"]),
		...mapState(["allAuth"]),
		roleBasedItems() {
			switch (this.role) {
				case "Interested Party":
					return this.allAuth.buyerItems;
				case "Insolvency Practitioner":
					return this.allAuth.sellerItems;
				case "Admin":
					return this.allAuth.adminItems;
			}
			return [];
		}
	}
};
</script>

<style lang="sass">

.desktop
	position: fixed
	top: 110px
	background: #FFFFFF
	border-style: solid 
	border-color: #C5C5C5
	border-width: 1px 1px 1px 0px
	box-sizing: border-box
	box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.2)
	border-radius: 1px
	z-index: 10


	
	
.mobile


</style>